@font-face {
  font-family: 'LINE Seed Sans TH';
  src: url('LINESeedSansTH-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LINE Seed Sans TH';
  src: url('LINESeedSansTH-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LINE Seed Sans TH';
  src: url('LINESeedSansTH-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
