.ant-btn {

  &:hover,
  &:focus {
    @apply border-primary border-opacity-90 text-primary;
  }

  &.ant-btn-dashed {
    @apply border border-solid border-primary text-primary;
  }

  &.ant-btn-primary {
    @apply border-primary bg-primary;

    &:hover,
    &:focus {
      @apply text-white border-primary bg-primary border-opacity-90 bg-opacity-90;
    }
  }

  &.ant-btn-link {

    &:hover,
    &:focus {
      @apply border-transparent text-primary text-opacity-70;
    }
  }

  &>span {
    &.anticon {
      @apply relative;
      // -top-0.5;
    }
  }
}


// Input
.ant-input,
.ant-input-affix-wrapper {
  // @apply rounded-md;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    @apply bg-primary border-primary rounded-full
  }
}

.ant-form-item {
  @apply mb-4;
}

.ant-switch {
  @apply bg-slate-400;
  &.ant-switch-checked {
    @apply bg-primary
  }
}

.ant-radio-button-wrapper {
  &:hover {
    @apply text-primary;
  }
}
.ant-radio-button-wrapper-checked {
  &:not(.ant-radio-button-wrapper-disabled){
    @apply border-primary text-primary;
    &:first-child {
      @apply border-r-primary;
    } 
    &:hover {
      &::before {
        @apply bg-primary;
      }
      @apply border-primary text-primary;
    }
    &::before {
      @apply bg-primary;
    }
  }
  &:not([class*=" ant-radio-button-wrapper-disabled"]) {
    &.ant-radio-button-wrapper:first-child {
      @apply border-primary;
    }
  }
}

.ant-tabs-ink-bar {
  @apply bg-primary;
}

.ant-tabs-tab {
  &:hover,&.ant-tabs-tab-active .ant-tabs-tab-btn {
    @apply text-primary
  }
}