@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import '~antd/dist/antd.css';
@import "./styles/antd.override.scss";

@import "./styles/fonts/LINE-Seed-TH-font-face/stylesheet.css";

@layer base {
  h1 {
    @apply text-3xl text-inherit;
  }

  h2 {
    @apply text-2xl text-inherit;
  }

  h3 {
    @apply text-lg text-inherit;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply font-primary;
}

#app-layout {
  .ant-layout-sider {
    @apply bg-transparent;

    .ant-layout-sider-children {
      @apply bg-white font-bold;

      .sidenav-header {
        // @apply text-primary;
      }

      .sidenav-content {

        .ant-menu.ant-menu-dark,
        .ant-menu-dark .ant-menu-sub,
        .ant-menu.ant-menu-dark .ant-menu-sub {
          @apply bg-white text-black;
        }

        .ant-menu-dark .ant-menu-item,
        .ant-menu-dark .ant-menu-item-group-title,
        .ant-menu-dark .ant-menu-item>a,
        .ant-menu-dark .ant-menu-item>span>a {
          @apply text-black;
        }

        .ant-menu-submenu {

          &.ant-menu-submenu-active,
          &.ant-menu-submenu-open {
            .ant-menu-submenu-title {

              .ant-menu-submenu-expand-icon,
              .ant-menu-submenu-arrow {

                &::before,
                &::after {
                  @apply bg-black;
                }
              }
            }
          }

          .ant-menu-submenu-title {

            .ant-menu-submenu-expand-icon,
            .ant-menu-submenu-arrow {

              &::before,
              &::after {
                @apply bg-black;
              }
            }
          }
        }

        .ant-menu-dark {

          .ant-menu-item:hover,
          .ant-menu-item-active,
          .ant-menu-submenu-active,
          .ant-menu-submenu-open,
          .ant-menu-submenu-selected,
          .ant-menu-submenu-title:hover {
            @apply  text-black;

            .ant-menu-submenu-expand-icon,
            .ant-menu-submenu-arrow {
              @apply text-white;
            }
          }

          .ant-menu-item-selected>a,
          .ant-menu-item-selected>span>a {
            @apply text-white;
          }

          .ant-menu-item-selected .ant-menu-item-icon,
          .ant-menu-dark .ant-menu-item-selected .anticon {
            @apply text-white;
          }

          .ant-menu-item:hover>a,
          .ant-menu-item-active>a,
          .ant-menu-submenu-active>a,
          .ant-menu-submenu-open>a,
          .ant-menu-submenu-selected>a,
          .ant-menu-submenu-title:hover>a,
          .ant-menu-item:hover>span>a,
          .ant-menu-item-active>span>a,
          .ant-menu-submenu-active>span>a,
          .ant-menu-submenu-open>span>a,
          .ant-menu-submenu-selected>span>a,
          .ant-menu-submenu-title:hover>span>a {
            @apply text-white;
          }

          &.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
            @apply bg-primary text-white;

            >a,
            >span>a {
              @apply text-white;
            }
          }
        }
      }
    }

    .ant-layout-sider-trigger {
      @apply bg-primary;
    }
  }


  .navbar-layout .site-layout-background {
    @apply bg-white;


    .ant-menu-light .ant-menu-submenu-title:hover,
    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
      @apply text-primary;
    }

    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after {
      @apply border-primary;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      @apply bg-primary-100 text-primary;
    }

  }
}

.ant-menu.ant-menu-dark, 
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  @apply bg-primary text-white;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  @apply bg-white text-black;
}
.ant-menu-dark .ant-menu-item:hover {
  @apply bg-white text-black;
}

.upload-full-w {
  .ant-upload {
    @apply w-full;
  }
}


.ql-editor {
  min-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.editor-content {

  .quill>.ql-container>.ql-editor.ql-blank::before {
    font-size: 20px;
    font-style: normal;
    color: #bfbfbf;
  }
  &.fix-toolbar {
    position: relative;

    .ql-toolbar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: #fff;
      z-index: 99;
      border-bottom: 1px solid #ccc !important;
    }
  }

  .ql-toolbar {
    border: none !important;

    // example custom toolber editor with css
    .ql-omega:after {
      content: "Ω";
    }
  }

  .ql-container {
    border: none !important;

    .ql-editor {
      @apply font-primary;
      font-size: 16px;
      min-height: 300px;
      border: 1px solid transparent;
      border-radius: 4px;

      &:hover {
        border: 1px solid #d9d9d9;
        font-size: 16px;
        @apply border-primary transition-colors duration-1000 ease-in-out;
      }

      &.ql-front {
        @apply text-lg h-auto;

        &:hover {
          border: 1px solid transparent !important;
        }
      }

      iframe {
        width: 100%;
        height: 56.25vw;
        max-height: calc(800px / 16 * 9);
      }
    }
  }

  &.sticky-content {
    .ql-container {
      .ql-editor {
        height: 500px;
        overflow:scroll
      }
    }
  }

  .quill {
    .ql-align-center {
      img {
        margin: auto;
      }
    }

    .ql-align-right {
      img {
        margin-left: auto;
      }
    }
  }
}

.form-product-info {
  .product-info {
    &:nth-child(odd) {
      // @apply bg-gray-50;
    }

    &:nth-child(even) {
      // @apply bg-white;
    }
  }
}

.full-h-screen-upload {
  @apply h-full;

  .ant-upload {
    @apply h-full;

    .ant-spin-nested-loading {
      @apply h-full;

      .ant-spin-container {
        @apply h-full;

        .ant-upload-text {
          @apply h-full;

        }
      }
    }
  }
}


.quote {
  &::before,
  &::after {
    display: block;
    height: 0;
    font-size: 84px;
    @apply text-primary;
  }

  &::before {
    position: relative;
    top: -4.5rem;
    content: '“';
    text-align: left;
  }

  &::after {
    position: relative;
    bottom: 1rem;
    right: 1rem;
    content: '”';
    text-align: right;
  }
}

// icons
span.anticon {
  &.anticon-left,
  &.anticon-right {
    @apply relative -top-1;
  }
}
.ant-tag > .anticon + span, .ant-tag > span + .anticon {
  position: relative;
  top: -3px;
}